import {Injectable} from '@angular/core';

import {Nullable} from '../types';

import {AuthUser} from './auth-user';

export interface PlanPricingLimitData {
  recipesLimit?: Nullable<number>;
  tasksLimit?: Nullable<number>;
  creditsLimit?: Nullable<number>;
  legacyTasksLimit?: Nullable<number>;
  connectionsLimit?: Nullable<number>;
  transactionsLimit?: Nullable<number>;
}

export type MembershipType = string | undefined | null;

export const TBP_MEMBERSHIP_TYPES = ['tbp_v1', 'tbp_v2'];
export const CBP_MEMBERSHIP_TYPES = ['cbp_v1'];

@Injectable()
export class PlanPricing {
  static isTaskBasedPricing(effectiveActionLimit?: number | null, membershipType?: MembershipType): boolean {
    return Boolean(this.isTbpMembership(membershipType) || effectiveActionLimit);
  }

  static isRecipeBasedPricing(billableFlowLimit?: number | null): boolean {
    return Boolean(billableFlowLimit);
  }

  static isCbpMembership(membershipType?: MembershipType): boolean {
    return Boolean(membershipType && CBP_MEMBERSHIP_TYPES.includes(membershipType));
  }

  static isTbpMembership(membershipType?: MembershipType): boolean {
    return Boolean(membershipType && TBP_MEMBERSHIP_TYPES.includes(membershipType));
  }

  private planLimits?: PlanPricingLimitData;

  constructor(private authUser: AuthUser) {}

  get isRecipeBasedPlan(): boolean {
    return PlanPricing.isRecipeBasedPricing(this.planLimits?.recipesLimit);
  }

  get isTaskBasedPlan(): boolean {
    return PlanPricing.isTaskBasedPricing(this.planLimits?.tasksLimit, this.authUser.membership.type);
  }

  get isLegacyTaskBasedPlan(): boolean {
    return Boolean(this.planLimits?.legacyTasksLimit);
  }

  get isConnectionBasedPlan(): boolean {
    return Boolean(this.planLimits?.connectionsLimit);
  }

  get isTransactionBasedPlan(): boolean {
    return Boolean(this.planLimits?.transactionsLimit);
  }

  setLimits(limits: PlanPricingLimitData) {
    this.planLimits = limits;
  }
}
